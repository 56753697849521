/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { HeaderLayout } from '@/components/header-layout';
import { Experience, useExperienceApi } from '@/webapi/use-experience-api';
import { ExperimentLoadedDetails } from '@/features/details/experiment-details';
import {
  MyExperiencesContext,
  newMyExperiencesContext,
} from '@/features/dashboard/experiences/context';
import { hideLoader, showLoader } from '@/components/PageLoader';
import { useAnalyticsApi } from '@/webapi/use-analytics-api';
import { Opt } from '@/features/details/date-pick/quick-range-select';

export function ExperimentDetails() {
  const { getExperience } = useExperienceApi();
  const { getVersions } = useAnalyticsApi();
  const [experience, setExperience] = useState<Experience>(undefined);
  const [expId] = useQueryParam(`expId`, StringParam);
  const [_, setVersionNumber] = useQueryParam<Opt>(`v`, NumberParam);
  const [__, setStep] = useQueryParam<Opt>(`o`, NumberParam);

  useAsyncEffect(
    async (isActive) => {
      const [expResp, versionsResp] = await Promise.all([
        getExperience(expId),
        getVersions(expId),
      ]);
      if (!isActive()) {
        return;
      }
      if (expResp?.experience) {
        expResp.experience.versions = versionsResp?.versions;
        expResp.experience.currentVersion = versionsResp?.maxVersion;
        versionsResp?.maxVersion?.version &&
          setVersionNumber(versionsResp?.maxVersion?.version);
        setStep(0);
      }
      setExperience(expResp.experience);
    },
    [experience?.metricsStartAt],
  );
  const isLoading = typeof experience === `undefined`;
  if (isLoading) {
    showLoader();
  } else {
    hideLoader();
  }

  return (
    <MyExperiencesContext.Provider value={newMyExperiencesContext()}>
      <HeaderLayout hideBackToLive>
        {!isLoading && (
          <ExperimentLoadedDetails
            experience={experience}
            setExperience={setExperience}
          />
        )}
      </HeaderLayout>
    </MyExperiencesContext.Provider>
  );
}
